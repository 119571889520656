<template>
  <div class="">
    <FullWidthBanner/>

    <GamesListingSection :games="newGames" ulr="#" :show-main-button="false" title="Giochi Online" :number-columns="3">
      <div slot="special-title" class="px-16 mt-8">
        <h1 class="uppercase text-secondary text-shadow text-xl font-bold">Nuovi Giochi</h1>
      </div>
    </GamesListingSection>

    <GamesListingSection :games="allGames" ulr="#" :show-main-button="false" title="Tutti i Giochi" :side-navigation="false" bottom-navigation>
      <div class="pb-4 pt-16 px-6 shadow-xl" slot="filters">
        <div class="inline-flex items-center divide-x divide-white">
          <div class="relative flex">
            <button type="button" @click="isProducerMenuOpen = !isProducerMenuOpen"
                    class="inline-flex items-center justify-center uppercase text-xs font-semibold px-12 group hover:text-primary focus:outline-none"
                    id="producer-menu" aria-haspopup="true" aria-expanded="true">
              Produttore
              <svg class="-mr-1 ml-2 h-4 w-4 group-hover:text-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                   fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"/>
              </svg>
            </button>

            <transition
                enter-active-class="transition ease-out duration-100 transform"
                enter-class="opacity-0 scale-95"
                enter-to-class="opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75 transform"
                leave-class="opacity-100 scale-100"
                leave-to-class="opacity-0 scale-95"
            >
              <div v-show="isProducerMenuOpen"
                   class="origin-top-right absolute right-0 mt-8 w-40 z-10 rounded-sm shadow-lg bg-gray-800">
                <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="producer-menu">
                  <a href="#" class="block px-4 py-2 text-xs text-white hover:text-primary"
                     role="menuitem">Cristaltec</a>
                  <a href="#" class="block px-4 py-2 text-xs text-white hover:text-primary"
                     role="menuitem">Octavian</a>
                  <a href="#" class="block px-4 py-2 text-xs text-white hover:text-primary"
                     role="menuitem">Capecod</a>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </GamesListingSection>
  </div>
</template>


<script>
import FullWidthBanner from "@/components/FullWidthBanner";
import GamesListingSection from "@/components/GamesListingSection";

export default {
  components: {
    FullWidthBanner,
    GamesListingSection,
  },

  data() {
    return {
      isProducerMenuOpen: false,
      newGames: [
        {
          image: '/img/c6a-games/1.png',
          gameUrl: '#'
        },
        {
          image: '/img/c6a-games/2.png',
          gameUrl: '#'
        },
        {
          image: '/img/c6a-games/3.png',
          gameUrl: '#'
        },
      ],
      allGames: [
        {
          image: '/img/c6a-games/1.png',
          gameUrl: '#'
        },
        {
          image: '/img/c6a-games/2.png',
          gameUrl: '#'
        },
        {
          image: '/img/c6a-games/3.png',
          gameUrl: '#'
        },
        {
          image: '/img/c6a-games/4.png',
          gameUrl: '#'
        },
        {
          image: '/img/c6a-games/5.png',
          gameUrl: '#'
        },
        {
          image: '/img/c6a-games/6.png',
          gameUrl: '#'
        },
        {
          image: '/img/c6a-games/7.png',
          gameUrl: '#'
        },
        {
          image: '/img/c6a-games/8.png',
          gameUrl: '#'
        },
        {
          image: '/img/c6a-games/9.png',
          gameUrl: '#'
        },
        {
          image: '/img/c6a-games/10.png',
          gameUrl: '#'
        },
      ],
    }
  }
}
</script>
